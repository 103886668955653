<template>
  <div class="wrap">
    <Title name="竞买/竞卖订单"></Title>
    <div class="table">
      <div class="thead">
        <div class="tr">
          <div class="th">产品信息</div>
          <div class="th">实付金额</div>
          <div class="th">交易状态</div>
          <div class="th">交易操作</div>
        </div>
      </div>
      <div class="tbody">
        <div class="tbody-item" v-for="item in tableData" :key="item.id">
          <div class="tbody-item-head">
            <p style="width: 130px">{{ item.add_time }}</p>
            <p style="width: 280px"><span>订单号：</span>{{ item.order_sn }}</p>
            <p>
              <span>{{ customer === 'buyder' ? '供应商' : '买家' }}：</span>{{ customer === 'buyder' ? item.sell_client_name : item.buy_client_name }}
            </p>
          </div>
          <div class="tr">
            <div class="td">
              <div class="goods">
                <div class="goods-info">
                  <div class="image">
                    <img :src="item.pic_url" alt="" />
                  </div>
                  <div class="info">
                    <p class="name">{{ item.product_name }}</p>
                    <p class="price">￥{{ item.order_price }}</p>
                  </div>
                </div>
                <p class="num">x{{ item.product_count }}</p>
              </div>
            </div>
            <div class="td">￥{{ item.order_price }}</div>
            <div class="td status">
              <div>
                <span v-if="item.order_status === 'DFH'">待发货</span>
                <span v-else-if="item.order_status === 'DSH'">待收货</span>
                <span v-else-if="item.order_status === 'DZF'">待付款</span>
                <span v-else-if="item.order_status === 'DKP'">待开发票</span>
                <span v-else-if="item.order_status === 'ZFC'">支付待确认</span>
                <span v-else-if="item.order_status === 'END'" class="finished">交易完成</span>
                <span v-else-if="item.order_status === 'QXC'">取消订单确认</span>
                <span v-else-if="item.order_status === 'QXS'">取消成功</span>
                <span v-else-if="item.order_status === 'DQS'">合同未签署</span>
                <span v-else-if="item.order_status === 'DFWQ'">对方还未签署</span>
                <span v-if="item.approveStatus === '1'" style="color:red">(待审核)</span>
                <span v-if="item.approveStatus === '3'" style="color:red">(审核不通过)</span>
              </div>
              <router-link :to="{path: '/User/TransactionDetail', query: {orderid: item.id, customer: customer, orderType: item.order_type}}">
                <span class="order-details">订单详情</span>
              </router-link>
            </div>
            <div class="td">
              <!--买家只有取消订单和支付操作-->
              <div class="button-box" v-if="customer === 'buyder'">
                <el-button plain v-if="item.order_status === 'DQS' && item.order_type === 'RE'" @click="submitOpr(item, 'QX')" style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  >取消订单</el-button
                >
                <el-button plain v-if="item.order_status === 'DQS' && item.order_type === 'AU'" @click="submitOpr(item, 'QX')" style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  >取消订单</el-button
                >
                <el-button
                  plain
                  v-if="item.order_status === 'DSH' && item.approveStatus == '0'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  v-show="isPermission(item, $route.query.customer)"
                  >确认收货</el-button
                >
                <el-button
                  plain
                  v-if="item.order_status === 'DZF' && item.approveStatus == '0'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  v-show="isPermission(item, $route.query.customer)"
                  >支付</el-button
                >

                <el-button
                  plain
                  v-if="(item.order_status === 'DQS' || item.order_status === 'DFWQ') && item.contractStatus === '1'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  v-show="isPermission(item, $route.query.customer)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >合同签署</el-button
                >
                <el-button
                  disabled
                  plain
                  v-if="(item.order_status === 'DQS' || item.order_status === 'DFWQ') && item.contractStatus === '0'"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >对方未签</el-button
                >
                <el-button
                  plain
                  v-if="item.approveStatus === '1'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  v-show="isPermission(item, $route.query.customer)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >审核</el-button
                >
                <el-button
                  plain
                  v-if="item.approveStatus === '3' && isPermission(item, $route.query.customer)"
                  @click="toOperate('/User/TransactionDetail', item)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >修改</el-button
                >
              </div>

              <!--卖家操作:发货，开票，取消订单确认，收款-->
              <div class="button-box" v-else-if="customer === 'sellder'">
                <el-button
                  plain
                  v-if="item.order_status === 'DFH' && item.approveStatus == '0'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  v-show="isPermission(item, $route.query.customer)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  >发货</el-button
                >
                <el-button
                  plain
                  v-else-if="item.order_status === 'DKP' && item.approveStatus == '0'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  v-show="isPermission(item, $route.query.customer)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  >开票</el-button
                >
                <el-button plain v-else-if="item.order_status === 'QXC'" @click="showDialog(item)" style="font-size: 12px; padding: 6px 15px; margin-right: 20px">确认取消</el-button>
                <el-button
                  plain
                  v-else-if="item.order_status === 'ZFC'"
                  v-show="isPermission(item, $route.query.customer)"
                  @click="toOperate('/User/TransactionDetail', item)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 20px"
                  >确认收款</el-button
                >
                <!-- <el-button plain v-else-if="item.order_status === 'ZFC'" @click="submitOpr(item, 'SK')" style="font-size: 12px; padding: 6px 15px; margin-right: 20px">确认收款</el-button> -->

                <el-button
                  plain
                  v-if="item.order_status === 'DQS'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  v-show="isPermission(item, $route.query.customer)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >合同签署</el-button
                >

                <el-button
                  plain
                  v-if="item.approveStatus === '1'"
                  @click="toOperate('/User/TransactionDetail', item)"
                  v-show="isPermission(item, $route.query.customer)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >审核</el-button
                >

                <el-button
                  plain
                  v-if="item.approveStatus === '3' && isPermission(item, $route.query.customer)"
                  @click="toOperate('/User/TransactionDetail', item)"
                  style="font-size: 12px; padding: 6px 15px; margin-right: 10px"
                  >修改</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页器 -->
    <div class="pager">
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="query.limit" :current-page="query.page" @current-change="handleCurrentChange"> </el-pagination>
    </div>
    <el-dialog title="取消订单确认" :visible.sync="dialogVisible" :show-close="true" width="380px" top="34vh">
      <div style="text-align: center">
        <el-button type="warning" round @click="confirmSubmit(currenItem, 'QXR')">拒绝</el-button>
        <el-button type="primary" round @click="confirmSubmit(currenItem, 'QXS')">同意</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {permission} from '@/modules/mixin/permission-mixin.js';
import Title from '../../component/Title';
import {buyderOrderList, sellderOrderList, oprOrderStatus} from '../../api';
export default {
  components: {
    Title
  },
  data() {
    return {
      currenItem: {},
      dialogVisible: false,
      customer: this.$route.query.customer, //buyer: 买方, supplier: 供应方
      tableData: [],
      total: 100,
      query: {
        page: 1,
        limit: 10,
        orderType: ''
      }
    };
  },
  mixins: [permission],
  created() {
    const customer = this.$route.query.customer;
    if (customer === 'buyder') {
      this.$store.commit('changeMenuType', 'BUY');
      this.queryBuyOrderList();
    } else if (customer === 'sellder') {
      this.$store.commit('changeMenuType', 'SELL');
      this.querySellderOrderList();
    }
  },
  methods: {
    // 跳转到操作的页面
    toOperate(path, item) {
      let data = {
        orderid: item.id,
        customer: this.customer,
        orderType: item.order_type,
        isHandleShow: true,
        tciId: item.contractId,
        orderNum: item.order_sn,
        clientName: item.buy_client_name
      };
      this.$router.push({path, query: data});
    },
    showDialog(item) {
      this.currenItem = item;
      this.dialogVisible = true;
    },
    jumpToOrderDetail(id, orderType) {
      this.$router.push({path: '/User/Paymented', query: {orderid: id, customer: this.customer, orderType: orderType, isHandleShow: true}});
    },
    // 操作
    handleClick(val) {
      console.log(val);
    },
    // 触发分页器
    handleCurrentChange(val) {
      this.query.page = val;
      const customer = this.$route.query.customer;
      if (customer === 'buyder') {
        this.$store.commit('changeMenuType', 'BUY');
        this.queryBuyOrderList();
      } else if (customer === 'sellder') {
        this.$store.commit('changeMenuType', 'SELL');
        this.querySellderOrderList();
      }
    },
    //買家list
    queryBuyOrderList() {
      buyderOrderList(this.query).then(res => {
        this.tableData = res.data.orderList;
        this.total = res.data.total;
      });
    },
    querySellderOrderList() {
      sellderOrderList(this.query).then(res => {
        this.tableData = res.data.orderList;
        this.total = res.data.total;
      });
    },
    // 提交交易操作
    submitOpr(item, status) {
      const title = status === 'QXS' || status === 'QX' || status === 'QXC' ? '取消订单' : status === 'SH' ? '收货确认' : '开票确认';
      const message = status === 'QXS' || status === 'QX' || status === 'QXC' ? '是否确认取消订单交易' : status === 'SH' ? '是否确认收货' : '是否将订单修改为已收款';
      this.$confirm(message, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.confirmSubmit(item, status);
      });
    },
    confirmSubmit(item, status) {
      const req = {
        orderId: item.id,
        status: status
      };
      oprOrderStatus(req).then(res => {
        if (res.errno === 0) {
          this.$notify.success({
            title: '成功',
            message: '提交成功'
          });
          if (this.customer === 'buyder') {
            this.queryBuyOrderList();
          } else {
            this.querySellderOrderList();
          }
        }
        this.dialogVisible = false;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.input-box {
  margin-bottom: 30px;

  .input-item {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    .tit {
      width: 120px;
      text-align: right;
      padding-right: 10px;

      .required {
        color: #fe1846;
      }
    }
  }
}
.wrap {
  background: #fff;

  .table {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .thead {
      background: #f5f5f5;
      color: #7d7d7d;
      font-weight: 600;

      .tr {
        display: flex;

        .th {
          flex: 1;
          height: 45px;
          line-height: 45px;
          text-align: center;
        }
        .th:first-child {
          flex: 3;
        }
      }
    }
    .tbody {
      color: #333;

      .tbody-item {
        border: 1px solid #eeeeee;
        margin-top: 14px;

        .tbody-item-head {
          display: flex;
          // height: 36px;
          line-height: 36px;
          background: #f5f5f5;
          color: #909399;

          p {
            color: #7d7d7d;
            margin: 0 20px;

            span {
              color: #bbbbbb;
            }
          }
        }
        .tr {
          display: flex;

          .td {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 45px;
            padding: 10px 0;
            box-sizing: border-box;
            border-left: 1px solid #eeeeee;

            &.status {
              flex-direction: column;
              justify-content: space-around;

              .order-details {
                color: #333;
                cursor: pointer;

                &:hover {
                  color: #3a8ee6;
                }
              }
              .finished {
                color: #999;
              }
            }
            .goods {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 38px 0 18px;

              .goods-info {
                display: flex;

                .image {
                  width: 80px;
                  height: 80px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .info {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  margin-left: 14px;

                  .name {
                    font-size: 16px;
                  }
                  .price {
                    font-size: 13px;
                    color: #999;
                  }
                }
              }
            }
            .button-box {
              text-align: center;

              .el-button + .el-button {
                margin-left: 0px;
                margin-top: 10px;
              }
            }
          }
          .td:first-child {
            flex: 3;
            border-left: 0;
          }
        }
      }
    }
  }
  .pager {
    margin: 10px 20px 50px 0;
    text-align: right;
  }
}
</style>
