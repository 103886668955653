import { render, staticRenderFns } from "./ReqOrder.vue?vue&type=template&id=5dbb1fa7&scoped=true&"
import script from "./ReqOrder.vue?vue&type=script&lang=js&"
export * from "./ReqOrder.vue?vue&type=script&lang=js&"
import style0 from "./ReqOrder.vue?vue&type=style&index=0&id=5dbb1fa7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbb1fa7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\xian\\work\\datacockpit\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5dbb1fa7')) {
      api.createRecord('5dbb1fa7', component.options)
    } else {
      api.reload('5dbb1fa7', component.options)
    }
    module.hot.accept("./ReqOrder.vue?vue&type=template&id=5dbb1fa7&scoped=true&", function () {
      api.rerender('5dbb1fa7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/User/components/order/ReqOrder.vue"
export default component.exports