var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("Title", { attrs: { name: "竞买/竞卖订单" } }),
      _c("div", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "tbody" },
          _vm._l(_vm.tableData, function (item) {
            return _c("div", { key: item.id, staticClass: "tbody-item" }, [
              _c("div", { staticClass: "tbody-item-head" }, [
                _c("p", { staticStyle: { width: "130px" } }, [
                  _vm._v(_vm._s(item.add_time)),
                ]),
                _c("p", { staticStyle: { width: "280px" } }, [
                  _c("span", [_vm._v("订单号：")]),
                  _vm._v(_vm._s(item.order_sn)),
                ]),
                _c("p", [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.customer === "buyder" ? "供应商" : "买家") +
                        "："
                    ),
                  ]),
                  _vm._v(
                    _vm._s(
                      _vm.customer === "buyder"
                        ? item.sell_client_name
                        : item.buy_client_name
                    ) + " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "tr" }, [
                _c("div", { staticClass: "td" }, [
                  _c("div", { staticClass: "goods" }, [
                    _c("div", { staticClass: "goods-info" }, [
                      _c("div", { staticClass: "image" }, [
                        _c("img", { attrs: { src: item.pic_url, alt: "" } }),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.product_name)),
                        ]),
                        _c("p", { staticClass: "price" }, [
                          _vm._v("￥" + _vm._s(item.order_price)),
                        ]),
                      ]),
                    ]),
                    _c("p", { staticClass: "num" }, [
                      _vm._v("x" + _vm._s(item.product_count)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "td" }, [
                  _vm._v("￥" + _vm._s(item.order_price)),
                ]),
                _c(
                  "div",
                  { staticClass: "td status" },
                  [
                    _c("div", [
                      item.order_status === "DFH"
                        ? _c("span", [_vm._v("待发货")])
                        : item.order_status === "DSH"
                        ? _c("span", [_vm._v("待收货")])
                        : item.order_status === "DZF"
                        ? _c("span", [_vm._v("待付款")])
                        : item.order_status === "DKP"
                        ? _c("span", [_vm._v("待开发票")])
                        : item.order_status === "ZFC"
                        ? _c("span", [_vm._v("支付待确认")])
                        : item.order_status === "END"
                        ? _c("span", { staticClass: "finished" }, [
                            _vm._v("交易完成"),
                          ])
                        : item.order_status === "QXC"
                        ? _c("span", [_vm._v("取消订单确认")])
                        : item.order_status === "QXS"
                        ? _c("span", [_vm._v("取消成功")])
                        : item.order_status === "DQS"
                        ? _c("span", [_vm._v("合同未签署")])
                        : item.order_status === "DFWQ"
                        ? _c("span", [_vm._v("对方还未签署")])
                        : _vm._e(),
                      item.approveStatus === "1"
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("(待审核)"),
                          ])
                        : _vm._e(),
                      item.approveStatus === "3"
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("(审核不通过)"),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            path: "/User/TransactionDetail",
                            query: {
                              orderid: item.id,
                              customer: _vm.customer,
                              orderType: item.order_type,
                            },
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "order-details" }, [
                          _vm._v("订单详情"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "td" }, [
                  _vm.customer === "buyder"
                    ? _c(
                        "div",
                        { staticClass: "button-box" },
                        [
                          item.order_status === "DQS" &&
                          item.order_type === "RE"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitOpr(item, "QX")
                                    },
                                  },
                                },
                                [_vm._v("取消订单")]
                              )
                            : _vm._e(),
                          item.order_status === "DQS" &&
                          item.order_type === "AU"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitOpr(item, "QX")
                                    },
                                  },
                                },
                                [_vm._v("取消订单")]
                              )
                            : _vm._e(),
                          item.order_status === "DSH" &&
                          item.approveStatus == "0"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确认收货")]
                              )
                            : _vm._e(),
                          item.order_status === "DZF" &&
                          item.approveStatus == "0"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("支付")]
                              )
                            : _vm._e(),
                          (item.order_status === "DQS" ||
                            item.order_status === "DFWQ") &&
                          item.contractStatus === "1"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("合同签署")]
                              )
                            : _vm._e(),
                          (item.order_status === "DQS" ||
                            item.order_status === "DFWQ") &&
                          item.contractStatus === "0"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { disabled: "", plain: "" },
                                },
                                [_vm._v("对方未签")]
                              )
                            : _vm._e(),
                          item.approveStatus === "1"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              )
                            : _vm._e(),
                          item.approveStatus === "3" &&
                          _vm.isPermission(item, _vm.$route.query.customer)
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm.customer === "sellder"
                    ? _c(
                        "div",
                        { staticClass: "button-box" },
                        [
                          item.order_status === "DFH" &&
                          item.approveStatus == "0"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("发货")]
                              )
                            : item.order_status === "DKP" &&
                              item.approveStatus == "0"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("开票")]
                              )
                            : item.order_status === "QXC"
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDialog(item)
                                    },
                                  },
                                },
                                [_vm._v("确认取消")]
                              )
                            : item.order_status === "ZFC"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "20px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确认收款")]
                              )
                            : _vm._e(),
                          item.order_status === "DQS"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("合同签署")]
                              )
                            : _vm._e(),
                          item.approveStatus === "1"
                            ? _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isPermission(
                                        item,
                                        _vm.$route.query.customer
                                      ),
                                      expression:
                                        "isPermission(item, $route.query.customer)",
                                    },
                                  ],
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              )
                            : _vm._e(),
                          item.approveStatus === "3" &&
                          _vm.isPermission(item, _vm.$route.query.customer)
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    padding: "6px 15px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toOperate(
                                        "/User/TransactionDetail",
                                        item
                                      )
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "pager" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.total,
              "page-size": _vm.query.limit,
              "current-page": _vm.query.page,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "取消订单确认",
            visible: _vm.dialogVisible,
            "show-close": true,
            width: "380px",
            top: "34vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit(_vm.currenItem, "QXR")
                    },
                  },
                },
                [_vm._v("拒绝")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmSubmit(_vm.currenItem, "QXS")
                    },
                  },
                },
                [_vm._v("同意")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thead" }, [
      _c("div", { staticClass: "tr" }, [
        _c("div", { staticClass: "th" }, [_vm._v("产品信息")]),
        _c("div", { staticClass: "th" }, [_vm._v("实付金额")]),
        _c("div", { staticClass: "th" }, [_vm._v("交易状态")]),
        _c("div", { staticClass: "th" }, [_vm._v("交易操作")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }